.Dropzone-input-wrapper {
  width: 100%;
}

.Dropzone-input {
  background-color: white;
  line-height: normal;
  align-self: center;
  border-radius: 2px;
  margin: 16px 0;
  border: none;
  height: 52px;
  width: 100%;
  font-size: 1.1em;
  font-family: normal 1em Helvetica, sans-serif;
  padding: 0px 22px;
  color: rgb(64, 64, 64);
  box-shadow: rgba(0, 0, 0, 0.14902) 0px 2px 2px 0px,
    rgba(0, 0, 0, 0.0784314) 0px 0px 0px 1px;
  transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1);
}

.Dropzone-input:hover {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 9px 0px,
    rgba(0, 0, 0, 0.0784314) 0px 0px 0px 1px;
  transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1);
}

.Dropzone {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: thin dashed #c2c2c2;
  background: #fcfcfc;
  width: 100%;
  min-height: 160px;
  padding: 16px 11px;
  border-radius: 5px;
  margin: 16px 0;
}

.Dropzone * {
  font-size: 13px;
}

.Dropzone-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center !important;
  width: 80%;
}
