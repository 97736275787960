:root {
  --mainBgColor: #f6f6f6;
  --mainBorderColor: #ccc;
  --mainOverColor: #fafafa;
  --leftSideWidth: 64px;
  --leftSideBgColor: white;
  --leftSideDisplay: block;
  --topBarHeight: 72px;
  --topBarBgColor: white;
  --middleBarDisplay: block;
  --middleBarHeight: 48px;
  --middleBarBgColor: white;
  --bottomBarHeight: 48px;
  --bottomBarBgColor: white;
  --tableHFColor: #9e9e9e;
}
@media (max-width: 640px) {
  :root {
    --leftSideWidth: 0px;
    --leftSideDisplay: none;
  }
}
#main #right-side #top-bar .hamburger {
  display: none !important;
}
@media (max-width: 640px) {
  #main #right-side #top-bar .hamburger {
    display: block !important;
  }
}
.hulk-bg:hover {
  background-color: var(--mainOverColor);
}
.border-bottom {
  border-bottom: 1px solid var(--mainBorderColor);
}
#main {
  display: flex;
  flex-wrap: nowrap;
}
#main #left-side {
  display: var(--leftSideDisplay);
  width: var(--leftSideWidth);
  height: 100vh;
  background: var(--leftSideBgColor);
  border-right: 1px solid var(--mainBorderColor);
}
#main #left-side .side-menu > * {
  display: block;
  width: 100%;
  text-align: center;
  margin: auto;
  padding: 16px;
  cursor: pointer;
}
#main #left-side .side-menu > *:active {
  transform: scale(0.9);
}
#main #right-side {
  width: calc(100vw - var(--leftSideWidth));
  height: 100vh;
  background: black;
}
#main #right-side #top-bar {
  width: 100%;
  height: var(--topBarHeight);
  background: var(--topBarBgColor);
  border-bottom: 1px solid var(--mainBorderColor);
  position: relative;
  overflow: hidden;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  /** * /
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px;
  /** */
}
#main #right-side #top-bar .top-bar-tools {
  height: 100%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}
#main #right-side #middle-bar {
  display: var(--middleBarDisplay);
  width: 100%;
  height: var(--middleBarHeight);
  background: var(--middleBarBgColor);
  border-bottom: 1px solid var(--mainBorderColor);
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
}
#main #right-side #content {
  width: 100%;
  height: calc(
    100vh - var(--topBarHeight) - var(--middleBarHeight) -
      var(--bottomBarHeight)
  );
  background: var(--mainBgColor);
  padding: 0px;
  overflow-x: auto !important;
  overflow-y: hidden !important;
}
#main #right-side #overlay {
  position: absolute;
  top: calc(var(--topBarHeight) + var(--middleBarHeight));
  left: var(--leftSideWidth);
  width: calc(100vw - var(--leftSideWidth));
  height: calc(
    100vh - var(--topBarHeight) - var(--middleBarHeight) -
      var(--bottomBarHeight)
  );
  background-color: rgba(240, 242, 255, 0.99);
  /**rgba(255, 255, 255, 0.98);*/
  display: none;
}
#main #right-side #bottom-bar {
  width: 100%;
  height: var(--bottomBarHeight);
  background: var(--bottomBarBgColor);
  border-top: 1px solid var(--mainBorderColor);
  display: flex;
  flex-wrap: nowrap;
  align-items: center !important;
  font-size: 13px;
  padding: 0 16px;
}
#main #right-side #content .list-header-col {
  display: flex !important;
  align-items: center !important;
  text-align: left;
}
#main #right-side #content .list-header-col > div:nth-child(1) {
  width: calc(100% - 72px);
  height: 100%;
}
#main #right-side #content .list-header-col > div:nth-child(2) {
  min-width: 72px;
  text-align: right;
}
.row-checkbox-wrapper {
  text-align: center;
  padding: 0;
  width: 48px;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
#main #right-side #content .row-content {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center !important;
  padding-right: 16px;
  width: 100%;
}
#main #right-side #content .row-content-col {
  padding-right: 16px;
}

.main-filters .top {
  padding: 0 10px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  height: var(--topBarHeight);
  border-bottom: 1px solid var(--mainBorderColor);
}
.main-filters {
  padding: 32px;
  width: 100%;
  height: 100%;
  overflow: auto;
  text-align: center;
}
.main-filters .modules > * {
  width: calc(100% - 32px);
  padding: 8px;
  background-color: var(--mainOverColor);
  border: 1px solid var(--mainBorderColor);
  border-radius: 3px;
  margin: 5px;
  display: inline-block;
  vertical-align: top;
  text-align: left;
}

.filter-module {
  position: relative;
}

/** * /
#main #right-side #content .row-options {
  text-align: center;
  width: 124px;
}
/** */

.filter-button-list {
  width: 320px;
  height: 50vh;
  font-size: 13px;
}

.filter-button-search {
  position: relative;
  width: 100%;
  padding: 0 10px;
  margin-top: -10px;
}
.filter-button-search-clear {
  position: absolute;
  right: 10px;
  bottom: 10px;
}

.virtualized-list {
  outline: none;
  padding: 0;
  margin: 0;
}
.virtualized-list-header {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  width: 100%;
  height: 48px;
  background-color: #fff;
  border-bottom: 1px solid #e0e0e0;
  font-size: 14px;
  font-weight: 500;
  color: var(--gray);
}
.virtualized-list-body {
  width: 100%;
  height: calc(100% - 48px);
}
.virtualized-list-row {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  border-bottom: 1px solid #e0e0e0;
  font-size: 13px;
  padding: 0;
}
.virtualized-list-row-content {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  width: calc(100% - 48px);
  height: 100%;
}
.virtualized-list-no-row {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  color: var(--gray);
}

#main .info {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  height: 100%;
}
#main .info > * {
  display: table-cell;
  white-space: nowrap;
}

.search-bar {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  width: 480px;
  max-width: calc(100vw - var(--leftSideWidth));
  padding: 0 10px !important;
  border: 0px solid var(--gray);
}
.search-input input::placeholder {
  font-weight: 200;
  color: var(--gray) !important;
}
.search-input {
  font-size: 13px !important;
  height: 36px !important;
}
.search-input input {
  margin: 0 !important;
  padding: 0 10px !important;
  border-radius: 3px !important;
}
.search-input input:focus {
  border: 1px solid var(--mainBorderColor) !important;
}
.search-input label {
  top: 22px !important;
  left: 8px !important;
}
.search-input hr {
  bottom: 0 !important;
  border: none !important;
}

.main-chips {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: 0 10px !important;
  border: 0px solid var(--gray);
}
.main-chips .chip {
  position: relative;
  border: 0px !important;
  height: 20px;
  margin: 2px !important;
}
.main-chips .chip > * {
  display: inline-block !important;
  vertical-align: middle !important;
  margin: 2px !important;
}
.main-chips .chip svg {
  position: absolute;
  top: 0px;
  left: 2px;
  fill: rgba(255, 255, 255, 0.5) !important;
  float: left;
  height: 14px !important;
  width: 14px !important;
  margin: 3px !important;
}
.main-chips .chip span {
  line-height: 14px !important;
  padding: 2px 4px 2px 20px !important;
}
.icon-edit {
  cursor:pointer;
}

.cand-match-click {
  text-decoration: underline;
  cursor: pointer;
}
.candidate-skills{
  text-align: center;
  padding: 0px 10px 15px;
}

.fc-button-remove:before {
  margin: 3px;
  width: 30px;
  height: 30px;
  content: '\00d7';
  font-size: 26px;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  color: red;
  cursor: pointer;
}

.fc-button-edit:before {
  margin: 3px;
  width: 30px;
  height: 30px;
  content: '\270E';
  font-size: 26px;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  color: blue;
  cursor: pointer;
}

.fc-group-button-icon {
  opacity: .6;
  font-weight: 100;
}

.fc-button-color-secondary {
  color: blue;
  cursor: pointer;
}

.fc-button-color-view {
  color: #454545;
  cursor: pointer;
}

.fc-dropdownmenu-contextmenu-item {
  padding: 5px;
  cursor: pointer;
}

.fc-dropdownmenu-contextmenu {
  position: fixed;
  background-color: white;
  z-index: 20002;
  padding: 5px;
  box-shadow: 0 1px 10px #0005;
}

.fc-dropdown-position {
  position: absolute;
  margin-top: 27px;
}

.fc-value-editor {
  margin: 3px;
  padding: 0 3px;
}

.pad-btm-15 {
  margin-bottom: 15px !important;
}

.removeClass {
  display: none
}

.must-have-save {
  float: right;
  color: #00bfa5 !important; 
  background-color: white !important;
  border: 1px solid #00bfa5 !important;
}

.admin-add-alter {
  cursor: pointer;
  float: left;
  margin-left: 15px;
}
.admin-add-alter-title {
  margin-right: 570px;
  margin-top: 5px;
}

.tag-field-hover {
  z-index: 10;
  position: absolute;
  margin-top: -85px;
  width: 250px;
}
.hover-white-space {
  white-space:pre;
}
.button-rec-add {
  background-color: green !important;
  color:white !important;
}
.button-rec-delete {
  background-color: red !important;
  color:white !important;
}

.rec-actions {
  color: white;
  cursor: pointer;
  margin: 2px 2px;
}

.cross-icon-jt {
  top: 18px;
  position: relative;
}