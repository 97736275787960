@import url("https://fonts.googleapis.com/css?family=Open+Sans+Condensed:300");
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 100px blue inset;
  box-shadow: 0 0 0 100px blue inset;
}
.login {
  text-align: left;
  background-repeat: no;
  background-size: cover;
  background-position: center;
}
.login-header {
  background-color: #00b89c;
  height: 60px;
  padding: 17px;
  color: black;
  text-align: right;
  box-sizing: border-box;
}
.login-header-logo {
  height: 26px;
  float: left;
}
.login-middle {
  position: relative;
  min-height: calc(100vh - 140px);
  box-sizing: border-box;
  overflow: hidden;
}
.login-center {
  position: absolute;
  width: 100%;
  bottom: 10%;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  border-radius: 4px;
  padding: 0 32px 32px;
}
.login-label {
  display: block;
  color: white;
  font-size: 42px;
  font-weight: bold;
  width: 100%;
  text-align: center;
  margin-bottom: 60px;
}
.login-title {
  font-size: 32px;
  letter-spacing: 2px;
  line-height: 124px;
  color: white;
}
.login-middle-form {
  background-color: white;
  width: 413px;
  margin: auto;
  padding: 25px;
  border-radius: 2px;
}
.login-middle-form label {
  font-size: 17px !important;
  color: rgba(0, 0, 0, 0.56) !important;
  font-weight: 500 !important;
  letter-spacing: 2px !important;
}
.login-middle-form .login-tos-label {
  font-size: 10px !important;
  padding-top: 10px;
}
.login-submit,
.login-submit * {
  height: 56px !important;
  line-height: 56px !important;
}
.login-submit {
  margin-top: 25px;
}
.login-submit button {
  background-color: #00b89c !important;
}
.login-submit span {
  color: white !important;
  letter-spacing: 2px !important;
}
.login-footer {
  background-color: transparent;
  min-height: 80px;
  color: white;
  text-align: center;
  padding: 20px;
}
.login-footer span {
  font-size: 0.7em;
  vertical-align: middle;
  line-height: 32px;
  letter-spacing: 1.5px !important;
}
.footer-button {
  color: white !important;
}
.footer-button span {
  text-transform: capitalize !important;
  font-weight: 300 !important;
}
@media only screen and (max-width: 768px) {
  .login-center {
    position: static;
    transform: unset;
    padding: 300px 0 0;
  }
  .login-middle-form {
    min-height: 360px;
    padding: 20px;
    width: 100%;
  }
}
