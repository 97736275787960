:root {
  --cyan-darker: #4f8078;
  --cyan: #00bfa5;
  --mui-cyan: #00bcd4;
  --purple: #536dfe;
  --purple-prime: #5776f8;
  --darker: #363636;
  --gray: #8a8a8a;
  --gray-darker: #808080;
  --screen: #536dfe;
  --screenInactive: #536dfe86;
  --placeholder: rgba(0, 0, 0, 0.5);
  --outline: 1px solid #dddddd;
  --antd-wave-shadow-color: #1890ff;
  --antd-wave-shadow-color: #00bcd4;
  --black-a05: rgba(0, 0, 0, 0.05);
  --mainBgColor: #f6f6f6;
  --mainBorderColor: #ccc;
  --mainOverColor: #fafafa;
  --leftSideWidth: 64px;
  --leftSideBgColor: white;
  --leftSideDisplay: block;
  --topBarHeight: 72px;
  --topBarBgColor: white;
  --middleBarDisplay: block;
  --middleBarHeight: 48px;
  --middleBarBgColor: white;
  --bottomBarHeight: 48px;
  --bottomBarBgColor: white;
  --tableHFColor: #9e9e9e;
  --commonBlack: rgba(0, 0, 0, 0.87);
}

@media (max-width: 640px) {
  :root {
    --leftSideWidth: 0px;
    --leftSideDisplay: none;
  }
}

.material-icons,
.material-icons-block {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: "liga";
  color: rgba(0, 0, 0, 0.54);
}

.material-icons.text-icon {
  font-size: 16px;
  float: left;
  position: absolute;
  margin: 16px 0 0 -16px;
}

.filter-control-item .material-icons {
  color: var(--purple) !important;
}

.filter-control-item .material-icons {
  margin: 0 !important;
}

.material-icons.arrow {
  font-size: 10px;
}

.navlink.active span,
.navlink.active .material-icons,
.navlink.active .material-icons-block {
  color: #536dfe !important;
}

.material-icons-block {
  display: block;
  line-height: 1;
}

.ant-switch-checked {
  background-color: var(--purple);
}

.MuiTablePagination-toolbar p {
  margin-bottom: 0 !important;
}

.ql-container {
  border-bottom-left-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
  background: #ffffffaa;
}

.ql-snow.ql-toolbar {
  display: block;
  background: var(--mainBgColor);
  border-top-left-radius: 0.5em;
  border-top-right-radius: 0.5em;
}

.ql-bubble .ql-editor {
  border: 1px solid #ccc;
  border-radius: 0.5em;
}

.ql-editor {
  min-height: 16em;
}

.ql-rtb-disabled .ql-toolbar {
  display: none !important;
}

.ql-rtb-disabled .ql-container {
  border: none !important;
}

.ql-rtb-disabled {
  opacity: 0.75 !important;
  filter: grayscale(1);
  cursor: not-allowed !important;
  border: 1px dashed #aaa !important;
  border-radius: 0.5em;
  cursor: not-allowed;
}

.ql-editor > p {
  margin: unset !important;
  display: block;
  margin-block-start: 1em !important;
  margin-block-end: 1em !important;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

/* ql-editor > p > br { display: none!important; } */
