.navlink {
    text-decoration: none;
}
a.navlink.active>div>span>div>div {
    color: #536dfe !important;
}
img.avatar {
    border-radius: 50%;
    height: 56px;
    width: 56px;
}