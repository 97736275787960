.Modal {
  height: 252px;
  width: 400px;
  margin: 20px;
  text-align: center;
}
.Modal-block {
  height: 48px;
  line-height: 48px;
}
.Modal-block > * {
  display: inline-block;
  vertical-align: bottom;
}
.Modal-block-block > * {
  display: block;
}
.ModalSchedule-TimePicker  {
  border-bottom-color: 1px solid #715EFF!important;
}
.ModalReminder-date {
  padding:8px 24px;
}

/**
* DialogFilter
*/
.DialogFilter .chip {
  display: inline-flex;
}

.DialogFilter .chip path {
  fill: #ffffff!important;
}
