.dropdown-button {
  padding: 5px !important;
  border: 1px solid gray !important;
  box-sizing: border-box !important;
  height: 23px !important;
  max-width: 160px !important;
}
.dropdown-button > div {
  padding: 0 !important;
  margin: 0 !important;
  font-size: 13px !important;
  line-height: 13px !important;
  width: 100% !important;
  height: 100% !important;
}
.dropdown-button > div > * {
  display: inline-block;
  vertical-align: top;
}
.dropdown-button > div > i {
  float: right;
  right: 5px;
  padding: 0 !important;
  margin: 0 5px 0 0 !important;
  line-height: 13px !important;
  width: 13px !important;
  height: 13px !important;
}
.dropdown-menu div {
  font-size: 13px !important;
}
