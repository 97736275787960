.Home {
  background-color: #f7f7f7;
}

.Home * {
  font-family: "Roboto", sans-serif;
}

.Home-Drawer-List {
  text-align: center;
}

.Home-middle {
  min-height: 100vh;
  text-align: right;
  position: relative;
  padding-top: 64px;
}

.Home-middle-list-left {
  display: inline-block;
  vertical-align: top;
  width: 100px;
  text-align: center;
  position: fixed;
  top: 64px;
  left: 0;
}

.Home-middle-list-left * {
  text-align: center;
  font-size: 11px;
}

.Home-middle-list-right {
  vertical-align: top;
  padding: 0;
}
.Home-middle .Home-middle-list-right .Home-Routes .snack-bar * {
  text-align: center !important;
}
.Home-middle-list-right * {
  text-align: left;
}

.Home-AppBar {
  background-color: white;
  text-align: left;
  position: fixed !important;
  overflow: hidden;
}
.Home-AppBar * {
  color: #212121;
}
.Home-AppBar path {
  fill: #212121;
}

.Home-AppBar .tabs {
  display: inline-block;
  width: calc(100vw - 600px);
  margin: -6px 240px 0 0;
  text-align: left;
  height: 60px;
}
.Home-AppBar .tabs .center {
  display: table;
  width: auto;
  margin: auto;
}
.Home-AppBar .tabs .navlink {
  display: inline-block;
  margin: 0 32px;
}
.Home-AppBar .tabs .navlink i {
  margin-top: 7px;
}
.Home-AppBar .tabs .navlink span {
  line-height: 28px;
}
.Home-AppBar .actions {
  display: inline-block;
  width: 150px;
}

@media only screen and (max-width: 768px) {
  .Home-middle-list-left {
    display: none;
  }
}
