* {
  box-sizing: border-box;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  /* * /  outline: 1px dotted red;  /* */
}

body {
  margin: 0;
  padding: 0;
}

h5 {
  color: rgba(0, 0, 0, 0.87);
  margin: 0 0 10px 0;
}

h6 {
  color: rgba(0, 0, 0, 0.87);
}

pre {
  white-space: pre-wrap;
}

input {
  outline: none !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
select:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 100px white inset !important;
  -webkit-text-fill-color: #536dfe !important;
  box-shadow: 0 0 0 100px white inset !important;
  background-color: #536dfe !important;
  background-image: none !important;
  color: rgb(0, 0, 0) !important;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.auto-widths,
.auto-widths * {
  width: auto !important;
}

.hulk:hover {
  font-weight: 700 !important;
  cursor: default;
}

.fnormal,
.fnormal>label,
.list .group-header,
.collection-controls,
.filter-control-placehoder,
.filter-control-item div>div,
.show-all label,
.sort-button span,
.filter-control-search-input,
.green-block h4,
.form,
.form p,
.form li,
.form td,
.form div,
.form span,
.form pre,
.row,
.row-card,
.row-card p,
.row-card li,
.row-card td,
.row-card div,
.row-card span,
.row-card pre,
.col,
.col div,
.col div>div>label {
  font-size: 13px !important;
}

div.card-col-header {
  font-size: 14px !important;
}

.FilterControl .filter-icon,
.form-header>div {
  font-size: 18px !important;
}

.f-arial,
.f-arial * {
  font-family: Arial, Helvetica, sans-serif !important;
}

.f-md {
  font-size: 0.875rem !important;
}

.f-sm {
  font-size: 0.75rem !important;
}

.f-11,
.f-11 * {
  font-size: 11px !important;
}

.f-12,
.f-12 * {
  font-size: 12px !important;
}

.f-13,
.f-13 * {
  font-size: 13px !important;
}

.f-14,
.f-14 * {
  font-size: 14px !important;
}

small,
.f-small,
.f-smalls *,
.form *.f-small,
span.show-more {
  font-size: 12px !important;
}

.f-normal,
.f-normal * {
  font-weight: normal !important;
}

.f-bold,
.f-bold * {
  font-weight: bold !important;
}

.filter-control-search-chip span,
.filter-clear-all span,
.filter-save-search span,
.form-header>div:nth-child(n + 2),
.warning-text {
  font-size: 12px !important;
}

.fbig,
.fbig>label,
.fbigs *,
.results,
.col>label,
.Tags .col>label,
.green-block h4 {
  font-size: 16px;
}

.fbigs *,
.results {
  font-family: 'Courier New', Courier, monospace !important;
  font-size: 1rem !important;
}

.snack-bar span {
  font-size: 20px !important;
}

.col>label,
.col>div>div>label {
  font-weight: 500 !important;
}

.form-header>div:nth-child(n + 2) {
  font-weight: 500 !important;
}

.green-block h4 {
  font-weight: 600;
  text-transform: uppercase;
}

div.stage-selected,
.form-header>div {
  font-weight: 600;
}

.row-card .details span {
  line-height: 32px;
}

.row-card .show-more>span {
  font-weight: 200;
  line-height: 24px;
  text-decoration: underline;
}

.col .candidate-id,
.col .candidate-id span {
  line-height: 40px;
}

.list .group-header {
  font-weight: normal;
  text-transform: capitalize;
}

.filter-control-search-chip {
  font-weight: normal !important;
}

.filter-control-search-chip span {
  font-weight: 500 !important;
  line-height: 16px !important;
}

.warning-text,
.filter-control-search-input {
  line-height: 12px !important;
}

.filter-control-placehoder {
  line-height: 26px !important;
}

.list-item-checkbox,
.filter-control-search-input label {
  line-height: 14px !important;
}

.filter-control-item {
  line-height: 24px !important;
}

.collection-controls {
  font-weight: 400;
}

.filter-control-search-input input::placeholder,
.filter-control-placehoder {
  font-weight: 200;
}

.truncate {
  /** to works it is needed define the width in your element */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.no-truncate {
  /** to works it is needed define the width in your element */
  white-space: unset !important;
  overflow: unset !important;
  text-overflow: unset !important;
}

.nowrap-scroll-x {
  max-width: 100%;
  white-space: nowrap;
  overflow-x: auto;
}

/** FONT: end */
/** COLORS: start */
/** LIME */
.clime,
.clime b,
.clime p,
.clime li,
.clime td,
.clime div,
.clime span,
.clime pre,
.clime i {
  color: lime !important;
}

/** BLUE */
.row-card .show-more>span,
.row-card .show-more>i,
.cblue,
.cblue b,
.cblue p,
.cblue li,
.cblue td,
.cblue div,
.cblue span,
.cblue pre,
.cblue i {
  color: #1675d7 !important;
}

/** PURPLE */
a,
.anchor,
.stage-selected .card-col-header,
.stage-selected .card-col-header i,
.row-card .first-item a>*,
.row-card .link-item a>*,
.filter-control-item,
.purples-over:hover *,
.ondev,
.ondev b,
.ondev p,
.ondev li,
.ondev td,
.ondev div,
.ondev span,
.ondev pre,
.ondev i,
.c-purple,
.c-purple b,
.c-purple p,
.c-purple li,
.c-purple td,
.c-purple div,
.c-purple span,
.c-purple pre,
.c-purple i {
  color: var(--purple) !important;
}

.purple-switch>div>div>div:nth-child(1) {
  background-color: #92a2fa !important;
}

.purple-switch>div>div>div:nth-child(2) {
  background-color: var(--purple) !important;
}

/** GRAY */
.col>small,
.card-picker-date input,
.collection-controls,
.filter-control-search-input input::placeholder,
/* placeholder color doesn't works right with rgba */
.filter-control-placehoder,
.col input:disabled,
.rich-text-box.disabled,
.list .group-header,
.form-header>div:nth-child(n + 2),
.row-card,
.row-card p,
.row-card li,
.row-card td,
.row-card div,
.row-card span,
.row-card pre,
.cgray,
.cgray b,
.cgray p,
.cgray li,
.cgray td,
.cgray div,
.cgray span,
.cgray pre,
.cgray i {
  color: var(--gray);
}

/** RED */
.row-card p.cred,
.row-card span.cred,
.cred,
.cred b,
.cred p,
.cred li,
.cred td,
.cred div,
.cred span,
.cred pre,
.cred i {
  color: rgb(234, 0, 0) !important;
}

/** GREEN */
.text-icon,
.Tags .col>label,
.col>label,
.col>div>label,
.cgreen,
.cgreen b,
.cgreen p,
.cgreen li,
.cgreen td,
.cgreen div,
.cgreen span,
.cgreen pre,
.cgreen i {
  color: #4f8078 !important;
}

/** CYAN */
.filter-save-search,
.filter-saved-searches,
.form-save-btn,
.ccyan,
.ccyan b,
.ccyan p,
.ccyan li,
.ccyan td,
.ccyan div,
.ccyan span,
.ccyan pre,
.ccyan i {
  color: var(--cyan) !important;
}

/** ORANGE */
.filter-clear-all,
.warning-text,
.corange,
.corange b,
.corange p,
.corange li,
.corange td,
.corange div,
.corange span,
.corange pre,
.corange i {
  color: orange !important;
}

/** YELLOW */
.cyellow,
.cyellow b,
.cyellow p,
.cyellow li,
.cyellow td,
.cyellow div,
.cyellow span,
.cyellow pre,
.cyellow i {
  color: yellow !important;
}

/** DARKER */
.card-column b,
h3,
.first-item b,
.col div>div>label,
.filter-control-item div>div,
.show-all label,
.filter-control-search-chip span,
.list-item-checkbox,
.list-item-checkbox div,
.list-item-checkbox svg:nth-child(2),
.rich-text-box,
.form-header>div,
.material-table table tr:nth-child(1) td,
.green-block>h4,
.cdarker,
.cdarker b,
.cdarker p,
.cdarker li,
.cdarker td,
.cdarker div,
.cdarker span,
.cdarker pre,
.cdarker i .cdarker path {
  color: rgba(0, 0, 0, 0.8) !important;
  fill: rgba(0, 0, 0, 0.8) !important;
}

/** LIGHTER */
.results,
.list-match,
.list-add-new,
.profile-header,
.profile-header p,
.profile-header i,
.profile-header div,
.profile-header span,
.tags span,
.snack-bar span,
p.clighter,
.clighter,
.clighter b,
.clighter p,
.clighter li,
.clighter td,
.clighter div,
.clighter span,
.clighter pre,
.clighter i {
  color: rgb(255, 255, 255) !important;
}

p.transparent {
  color: transparent !important;
}

/** BACKGROUND, BORDER and other stuff COLORS */
.filter-control-search {
  background-color: white !important;
}

.ondev,
.ondev b,
.ondev p,
.ondev li,
.ondev td,
.ondev div,
.ondev span,
.ondev pre,
.ondev i {
  background-color: transparent !important;
}

/*
  .stage-selected{
    background-color: #F7F7FF!important;
  }
  */
.filter-control-search-chip {
  background-color: #b9c3ff !important;
}

.filter-control-search-chip svg {
  fill: rgba(0, 0, 0, 0.54) !important;
}

.filter-control-search {
  border-color: #d6d6d6 !important;
}

.filter-control-item {
  border-right-color: #e0e0e0 !important;
}

.rich-text-box.disabled {
  border-color: rgba(0, 0, 0, 0.3) !important;
}

.bgcolor1 {
  background-color: #ffffff !important;
}

.bgcolor2,
.edit {
  background-color: #f7f7f7 !important;
}

.bgcolorred {
  background-color: rgb(255, 236, 229) !important;
}

.bgcolorredYellow {
  background-color: lightgoldenrodyellow !important;
}

.edit-account-list-col {
  background-color: rgba(255, 255, 255, 0.84);
}

.list-match,
.list-add-new,
.bgcolor3,
.bgcolor3 button {
  background-color: var(--cyan) !important;
}

.filter-save-search,
.filter-saved-searches,
.form-save-btn {
  background-color: white !important;
  border: 1px solid rgb(0, 191, 165) !important;
}

.button-1 {
  background-color: var(--cyan) !important;
  color: white !important;
}

button.button-flat-darker {
  background-color: transparent !important;
  border: 0px solid var(--darker) !important;
  margin: 2px !important;
}

button.button-flat-darker * {
  color: var(--darker) !important;
}

button.button-flat-cyan {
  background-color: transparent !important;
  border: 0px solid var(--cyan) !important;
  margin: 2px !important;
}

button.button-flat-cyan * {
  color: var(--cyan) !important;
}

button.button-cyan,
.button-cyan button {
  background-color: var(--cyan) !important;
  border: 1px solid var(--cyan) !important;
  margin: 2px !important;
}

button.button-cyan,
button.button-cyan *,
.button-cyan button * {
  color: white !important;
}

button.button-white-cyan,
.button-white-cyan button {
  background-color: white !important;
  border: 1px solid var(--cyan) !important;
  margin: 2px !important;
}

button.button-white-cyan *,
.button-white-cyan button * {
  color: var(--cyan) !important;
}

button.button-white-bordered,
.button-white-bordered button {
  background-color: transparent !important;
  border: 2px solid white !important;
  margin: 2px !important;
}

button.button-white-bordered *,
.button-white-bordered button * {
  color: white !important;
  font-size: 14px !important;
  line-height: initial !important;
}

button:disabled {
  opacity: 0.75 !important;
  filter: grayscale(1);
  cursor: not-allowed !important;
  /* 
  background-color: gray !important;
  color: darkgrey !important;
  filter: invert(1) grayscale(1); 
  */
}

.warning-text,
.warning-chip {
  border-color: orange !important;
}

.filter-clear-all {
  background-color: white !important;
  border: 1px solid orange !important;
}

.green-block {
  background-color: #e8f9f7 !important;
}

.list-item-checkbox svg:nth-child(2) {
  fill: #536dfe !important;
}

.filter-toolbar {
  background-color: transparent !important;
}

.match-profile-html-item {
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.border-bottom-gray {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
}

.failure.page {
  background-color: #ededed;
  min-height: 100vh;
}

.failure.page .error {
  color: #ededed !important;
}

.results {
  background-color: rgba(7, 11, 0, 0.8);
}

.btn-white-crimson {
  background-color: white !important;
  border: 1px solid crimson !important;
  color: crimson !important;
  margin: 0.2rem 0.1rem !important;
}

.btn-white-cyan {
  background-color: white !important;
  border: 1px solid var(--cyan) !important;
  color: var(--cyan) !important;
  margin: 0.2rem 0.1rem !important;
}

.btn-cyan-white {
  background-color: var(--cyan) !important;
  color: white !important;
  margin: 0.2rem 0.1rem !important;
}

/** COLORS: end */
.announcement-paper {
  max-width: 990px;
  margin: auto;
}

.announcement-headers,
.announcement-row {
  min-width: 900px;
}

.view.announcement-paper {
  max-width: 1256px;
  margin: auto;
}

.view.announcement-headers,
.view.announcement-row {
  min-width: 1010px;
}

.announcement-headers {
  padding: 10px 0;
  border-bottom: 1px solid #ccc;
}

.announcement-chip {
  display: block;
  width: 200px;
  height: 26px;
  padding: 2px;
  border-radius: 15px;
  overflow: hidden;
}

.announcement-chip>* {
  margin: 4px;
  display: inline-block;
  vertical-align: middle;
  font-size: 13px;
}

.announcement-chip.green {
  background-color: #d4ead4;
}

.announcement-chip.yellow {
  background-color: #ffeed4;
}

.announcement-chip.red {
  background-color: #ffc6cc;
}

.management.announcement-headers>div:nth-child(2),
.management.announcement-headers>div:nth-child(3),
.management.announcement-headers>div:nth-child(4) {
  width: 256px;
}

.management.announcement-headers>div:nth-child(1),
.management.announcement-row>div:nth-child(1) {
  width: 32px;
}

.view.announcement-headers,
.view.announcement-row {
  display: table;
  min-height: 50px;
}

.view.announcement-headers>div,
.view.announcement-row>div {
  display: table-cell;
  min-width: 256px;
}

.view.announcement-headers>div:nth-child(1),
.view.announcement-row>div:nth-child(1) {
  min-width: 150px;
}

.view.announcement-headers>div:nth-child(4),
.view.announcement-row>div:nth-child(4) {
  min-width: 500px;
}

.view.announcement-headers>div:nth-child(5),
.view.announcement-row>div:nth-child(5) {
  min-width: 50px;
}

/* SNACK BAR */
.snack-bar>* {
  width: 100vw !important;
  max-width: 100vw !important;
  text-align: center !important;
}

/* TOOLS */
.align-right.tools>* {
  margin-left: 16px;
}

.list.tools>* {
  margin: 24px 0;
}

.list.tools {
  padding: 24px;
}

.inline-blocks.tools>* {
  margin: 0px 16px 16px 0;
}

/* LIST */
.list {
  position: relative;
  padding-top: 22px;
}

.list-scroller {
  padding: 0 24px 24px;
  max-height: calc(100vh - 310px);
  overflow: scroll;
  outline: none;
}

.list-scroller .row-card:last-child {
  margin-bottom: 2.5rem !important;
}

.list-scroller.no-tabs {
  max-height: calc(100vh - 122px) !important;
}

.sort-buttons {
  margin: auto;
  text-align: left !important;
  margin: 0 24px;
}

.sort-button {
  display: inline-block !important;
  margin: 0 !important;
  vertical-align: bottom;
  border-radius: 0 !important;
  color: rgba(0, 0, 0, 0.64) !important;
  border-bottom: 3px solid var(--purple) !important;
}

.tab-selected {
  background-color: var(--purple) !important;
  color: #fff !important;
}

.table-scroll-x>div:nth-child(1)>div:nth-child(2) {
  min-width: 1024px !important;
  overflow-x: auto !important;
}

.sort-button div {
  text-align: center !important;
}

.sort-button span {
  text-transform: capitalize !important;
  letter-spacing: 0.75px !important;
  font-weight: 500 !important;
}

.account-toolbar {
  width: 100%;
  text-align: right;
  padding: 24px 24px 0;
}

.list-match,
.list-add-new {
  margin-left: 2px !important;
}

.list-match,
.filter-saved-searches,
.filter-saved-searches *,
.list-add-new,
.list-add-new * {
  text-align: center;
}

.list .group-header {
  margin: 24px 16px;
}

.list-floating-button {
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 1;
}

.list-floating-button * {
  text-align: center !important;
}

/* CARD */
/*
  .stage-selected .card-col-header,
  .stage-selected input {
    text-align: center;
  }
  */
.card-col-header {
  margin-bottom: 10px;
}

.card-columns>*>* {
  display: block !important;
}

.card-options>* {
  display: inline-block !important;
  vertical-align: middle !important;
}

.card-col-dropdown {
  margin-top: 10px !important;
}

.card-col-button {
  margin-top: 10px !important;
}

/* ROW CARD */
.row-card {
  margin: auto;
}

.row-card .first-item,
.row-card .first-col {
  padding-left: 20px !important;
  padding-right: 2px !important;
}

.row-card .last-col {
  text-align: right !important;
  width: 100px;
  padding-left: 2px !important;
  padding-right: 20px !important;
}

.row-card .link-item,
.row-card .mid-col {
  padding-left: 2px !important;
  padding-right: 2px !important;
}

.row-card .first-item a,
.row-card .link-item a {
  text-decoration: none;
}

.row-card .v-align-mid>* {
  display: inline-block;
  vertical-align: middle;
}

.row-card .show-more>span {
  display: inline-block;
  vertical-align: top;
  width: 56px;
  height: 24px;
}

.row-card .show-more>i {
  display: inline-block;
  width: 24px;
  height: 24px;
  margin: 0;
}

/** ROW CARD: collapsed-view */
.row-card .collapsed-view tr {
  border-bottom-width: 0 !important;
}

.row-card .collapsed-view tr,
.row-card .collapsed-view td {
  height: 24px !important;
}

.row-card .collapsed-view td {
  outline: none;
}

.row-card.candidate .collapsed-view td {
  white-space: normal !important;
}

.row-card.candidate .collapsed-view .tr-match-override .td-match-override {
  white-space: nowrap !important;
}

/** important to slide header content on small screens */
.row-card .collapsed-view {
  min-width: 768px;
  margin-bottom: 10px !important;
}

/** important to slide header content on small screens */
.row-card>div:nth-child(1)>div:nth-child(1)>div:nth-child(2) {
  min-width: 768px !important;
  overflow-x: auto !important;
}

.starred,
.row-card .star {
  position: relative !important;
  display: inline-block !important;
  height: 24px !important;
  width: 24px !important;
  margin: 0 !important;
}

.starred *,
.row-card .star * {
  position: absolute !important;
  height: 100% !important;
  width: 100% !important;
  top: 0 !important;
  left: 0 !important;
  margin: 0 !important;
}

/* ROW CARD: expanded-view */
.row-card .card-header {
  text-transform: uppercase;
}

.row-card .details {
  width: 100%;
}

.row-card .details span {
  display: inline-block;
  width: 20%;
  margin: 0;
}

.row-card .contents {
  margin: 16px 0 24px 48px;
  padding: 0 !important;
}

.row-card .contents pre {
  width: 100%;
  white-space: pre-wrap;
  /* Since CSS 2.1 */
  white-space: -moz-pre-wrap;
  /* Mozilla, since 1999 */
  white-space: -pre-wrap;
  /* Opera 4-6 */
  white-space: -o-pre-wrap;
  /* Opera 7 */
  word-wrap: break-word;
  /* Internet Explorer 5.5+ */
}

.card-eng-status {
  border: 1px solid #eee;
  border-radius: 2px;
  padding: 5px;
  margin-top: 5px;
}

.date-picker>.f-small {
  min-width: 60px;
}

.date-picker .btn-remove {
  position: absolute !important;
  top: 0;
  right: 0;
}

.date-picker:hover {
  outline: 1px solid;
  border-radius: 2px;
}

.date-picker:hover .btn-remove {
  display: inline !important;
}

.date-picker .btn-mdash,
.date-picker .btn-mdash * {
  background-color: transparent !important;
  min-width: 70px !important;
  width: 70px !important;
  height: 16px !important;
  line-height: 16px !important;
  padding: 0 !important;
  margin: 0 !important;
  text-align: center;
}

.card-picker-date {
  width: 70px !important;
}

.card-picker-date,
.card-picker-date * {
  cursor: pointer !important;
  padding: 0 !important;
  margin: 0 !important;
  border: none !important;
  outline: none !important;
  vertical-align: top !important;
}

.card-picker-date,
.card-picker-date>div>input {
  width: 70px !important;
  height: 16px !important;
}

/* FILTER CONTROL */
.FilterControl {
  margin: auto;
  padding: 24px 24px 0;
}

.filter-control-search {
  position: relative;
  margin: 8px 0 0;
  border-radius: 3px;
  border: 0.5px solid;
}

.filter-control-search-chips {
  width: calc(100% - 4px);
  padding: 4px 0;
  min-height: 36px;
}

.filter-control-search-chips>* {
  display: inline-block !important;
  vertical-align: middle !important;
}

.filter-action-bar {
  position: absolute !important;
  bottom: 4px !important;
  right: 4px !important;
}

.filter-save-search {
  width: 120px;
  margin-right: 2px !important;
}

.filter-save-search,
.filter-save-search * {
  height: 30px !important;
  line-height: 30px !important;
  text-align: center;
}

.filter-clear-all {
  width: 120px;
}

.filter-clear-all,
.filter-clear-all * {
  height: 30px !important;
  line-height: 30px !important;
  text-align: center;
}

.filter-control-search-chip {
  border: 0px !important;
  margin: 2px !important;
}

.filter-control-search-chip>* {
  display: inline-block !important;
  vertical-align: middle !important;
  margin: 2px !important;
}

.filter-control-search-chip svg {
  float: left;
  height: 14px !important;
  width: 14px !important;
  margin: 4px !important;
}

.filter-control-search-chip span {
  padding-left: 4px !important;
}

.filter-form>* {
  display: inline-block;
  vertical-align: middle;
}

.filter-form>div {
  width: calc(100% - 48px) !important;
}

.filter-icon {
  margin: 0 8px;
}

.filter-control-search-input {
  width: calc(100% - 240px) !important;
  height: 36px !important;
}

.filter-control-search-input input {
  margin: 0 !important;
  padding: 0px !important;
}

.filter-control-search-input label {
  top: 22px !important;
  left: 8px !important;
}

.filter-control-search-input hr {
  bottom: 0 !important;
}

.filter-control-item {
  border: 0px !important;
  min-height: 24px !important;
  border-right: 0.5px solid !important;
}

.filter-control-item>div>div {
  /** fix space between label and icon */
  padding: 0 32px 0 16px !important;
}

.filter-toolbar {
  table-layout: auto !important;
  height: auto !important;
}

.filter-toolbar td {
  padding: 0 !important;
  height: auto !important;
  /* @todo confirm if something broken 2021-07-09 µ */
  /*  align-items: center;  */
}

.filter-toolbar td:nth-child(2) {
  text-align: right !important;
}

/** FILTER CONTROL: menus */
.list-item-input {
  margin: -24px 8px 0 !important;
  width: calc(100% - 16px) !important;
}

.list-item-checkbox {
  padding: 8px 8px 8px 32px !important;
}

.list-item-checkbox div:first-child {
  height: auto !important;
  width: 16px !important;
  top: 1px !important;
  left: 2px !important;
}

.list-item-checkbox svg:nth-child(2) {
  height: 24px;
  width: 24px;
}

/* SORT CONTROLS */
.show-all {
  display: inline-block !important;
  min-width: 175px !important;
}

.show-all label {
  margin-left: -10px;
}

/* RICH TEXT BOX */
.rich-text-box {
  margin: 5px 0;
}

.rich-text-box .Dropdown__root___3ALmx {
  margin: 0;
  top: 0px;
}

.rich-text-box .Dropdown__root___3ALmx select {
  height: 30px;
  outline: none;
}

.ui-email-preview .rich-text-box .public-DraftEditor-content {
  max-height: calc(100vh - 672px);
}

.rich-text-box .public-DraftEditor-content {
  min-height: 10em;
  max-height: 30em;
}

.rich-text-box.half-height .public-DraftEditor-content {
  min-height: 10em;
  max-height: 15em;
}

.rich-text-box.match-new .public-DraftEditor-content {
  min-height: fit-content;
}

.text-field.match-new {
  min-height: fit-content;
  /*max-height:15em;*/
}

.rich-text-box.disabled {
  border: 2px dotted;
}

.rich-text-box.disabled * {
  cursor: not-allowed;
}

/* FORMS */
.edit {
  width: 100vw;
  min-height: 100vh;
  padding-bottom: 24px;
}

.form {
  position: relative;
}

.form-save-btn {
  position: absolute !important;
  top: 16px !important;
  right: 16px !important;
  z-index: 2;
}

.form-header>div:nth-child(n + 2) {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.form-header>div:nth-child(2) {
  margin-top: 8px;
}

.recruiter-rating>div:nth-child(3) {
  max-width: 30% !important;
}

.relation-ship>div:nth-child(4) {
  max-width: 25% !important;
}

.candidate-resume {
  width: 100%;
  height: 32px;
}

.candidate-resume>* {
  display: inline-block;
  vertical-align: middle;
}

.candidate-checkboxes>div {
  display: inline-block !important;
}

.candidate-prefer>div:nth-child(2) {
  display: flex;
}

.candidate-prefer>div:nth-child(2)>div {
  width: 20% !important;
}

.checkbox-group {
  display: flex;
  flex-wrap: wrap;
}

.checkbox-group>div {
  margin: 16px 0;
  width: 20% !important;
}

.radio-button-group {
  display: flex;
  flex-wrap: wrap;
}

.radio-button-group>div {
  margin: 16px 0;
  width: 20% !important;
}

.radio-button-group._25>div {
  margin: 16px 0;
  width: 25% !important;
}

.col>label {
  display: block;
  margin: 12px 0 0;
}

.col label {
  color: var(--cyan-darker) !important;
  font-family: 'Roboto', sans-serif !important;
  font-size: 16px !important;
  font-weight: 500 !important;
}

.col label {
  text-transform: capitalize;
}

.col h3 {
  text-transform: capitalize !important;
  color: var(--gray-darker) !important;
  font-size: 0.8rem !important;
}

/** ACCOUNT */
.edit-account-list-row li {
  margin: 16px 0;
}

.edit-account-list-row .col {
  margin: 8px;
  min-height: 64px;
}

.edit-account-list-col {
  position: relative;
}

.edit-account-list-col .icon-button {
  position: absolute !important;
  bottom: 0;
  right: 0;
}

/** MATCH */
.match {
  width: 100vw;
  min-height: 100vh;
  background-color: #fafaff;
}

.match-left-side {
  display: inline-block;
  width: 580px;
  padding: 8px 0 24px 24px;
}

.match-right-side {
  width: calc(100% - 580px);
}

.match-profile-details {
  overflow: auto;
  max-height: calc(100vh - 320px);
  margin-top: 16px;
}

.match-profile-html-item {
  border-radius: 3px;
  padding: 4px;
}

/** SLIDER */
.slider {
  margin-top: 8px;
}

.slider-comp {
  width: calc(100% - 76px) !important;
  height: 30px !important;
  margin-right: 16px !important;
}

.slider-comp>div {
  margin-top: 6px !important;
}

.slider-input {
  width: 60px !important;
  height: 30px !important;
  border: 1px solid #ddd !important;
  border-radius: 4px;
}

.slider-input hr {
  display: none !important;
}

.slider-input,
.slider-input * {
  padding: 0 !important;
  margin: 0 !important;
  text-align: center !important;
}

/** TAGBOX */
.tag-box {
  min-width: 364px !important;
}

/** COMMON */
.anchor,
.row-card span.anchor {
  color: var(--purple) !important;
  text-decoration: underline;
}

.border {
  border: 1px solid !important;
}

.borders>* {
  border: 1px solid !important;
}

.border-1 {
  border: 1px solid #dedede !important;
  border-radius: 3px;
}

.centered-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.centered-content>* {
  margin: 0;
}

.display-cell {
  display: table-cell !important;
}

.relative {
  position: relative !important;
}

.cursor-default {
  cursor: default !important;
}

.anchor,
.cursor-pointer,
.pointer {
  cursor: pointer !important;
}

.cursor-default {
  cursor: default !important;
}

.flex {
  display: flex;
  flex-wrap: wrap;
}

.flex.nowrap {
  flex-wrap: nowrap !important;
}

.inline-blocks {
  white-space: normal !important;
}

.inline-blocks>* {
  display: inline-block !important;
  vertical-align: middle !important;
}

.inline-blocks.no-wrap {
  white-space: nowrap !important;
}

.block {
  display: block !important;
}

.blocks>* {
  display: block !important;
}

.align-right {
  text-align: right !important;
}

.align-rights * {
  text-align: right !important;
}

.align-center {
  text-align: center !important;
}

.align-left {
  text-align: left !important;
}

.bold,
.bolder {
  font-weight: bold;
}

.v-align-top {
  vertical-align: top !important;
}

.v-align-tops>* {
  vertical-align: top !important;
}

.v-align-mid {
  vertical-align: middle !important;
}

.v-aligns-middle>* {
  vertical-align: middle !important;
}

.v-aligns-bottom>* {
  vertical-align: bottom !important;
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.hover-bg:hover {
  background-color: rgba(0, 0, 0, 0.05) !important;
}

.main-padding {
  padding-top: 24px !important;
  padding-left: 24px !important;
  padding-right: 24px !important;
  padding-bottom: 24px !important;
}

.normal-padding,
.padding-10 {
  padding-top: 10px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
  padding-bottom: 10px !important;
}

.padding-top-bottom {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.no-side-paddings,
.no-side-paddings * {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.min-100vh {
  min-height: 100vh !important;
}

.none {
  display: none !important;
}

.padding-top {
  padding-top: 10px !important;
}

.padding-tops>* {
  padding-top: 10px !important;
}

.padding-left {
  padding-left: 10px !important;
}

.padding-top-left {
  padding-top: 10px !important;
  padding-left: 10px !important;
}

.padding-side {
  padding-left: 24px !important;
  padding-right: 24px !important;
}

.margin-top {
  margin-top: 10px !important;
}

.margin-tops>* {
  margin-top: 10px !important;
}

.margin-top-bottom {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.margin-right {
  margin-right: 10px !important;
}

.margin-rights>* {
  margin-right: 10px !important;
}

.m10s>* {
  margin: 10px !important;
}

.main-margin {
  margin-top: 24px !important;
  margin-left: 24px !important;
  margin-right: 24px !important;
  margin-bottom: 24px !important;
}

.no-margin,
.no-margins>* {
  margin-top: 0 !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  margin-bottom: 0 !important;
}

.normal-margin {
  margin-top: 10px !important;
  margin-left: 10px !important;
  margin-right: 10px !important;
  margin-bottom: 10px !important;
}

.translucid {
  opacity: 0.5 !important;
}

.dotted {
  border: 1px dotted !important;
}

.absolute-bottom-right {
  position: absolute;
  bottom: 0;
  right: 0;
}

.fixed-bottom-right {
  position: fixed;
  bottom: 0;
  right: 0;
}

.fixed-bottom {
  position: fixed;
  bottom: 0;
}

.fullwidth {
  width: 100%;
}

.capitalize {
  text-transform: capitalize !important;
}

.relative {
  position: relative !important;
}

.fixed {
  position: fixed !important;
}

.nowrap {
  white-space: nowrap !important;
}

.scroll-x {
  overflow-x: auto !important;
}

.scroll-y {
  overflow-y: auto !important;
}

.scroll-x-visible {
  overflow-x: visible !important;
}

.scroll-y-visible {
  overflow-y: visible !important;
}

.scroll {
  overflow: auto !important;
}

.toolbar {
  min-width: 320px;
}

.layout-auto {
  table-layout: auto !important;
}

.warning-text {
  display: block;
  position: relative;
}

.warning-chip {
  float: left;
  margin: 0px 10px !important;
  padding: 0px 5px !important;
  background: transparent !important;
  border: 1px solid !important;
}

.green-block {
  display: block;
  margin: 16px;
}

.ondev {
  opacity: 0.4;
}

.ondev.navlink.active {
  opacity: 0.6;
}

.results {
  padding: 24px;
  max-height: calc(100vh - 200px);
  overflow: auto;
  border-radius: 3px;
}

.wrapperCopy {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99999;
  width: calc(100vw - 48px);
  height: calc(100vh - 48px);
  background-color: white;
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
  border-radius: 2px;
  padding: 30px;
  overflow: auto;
}

.icon16,
.icon16 * {
  margin: 0 !important;
  padding: 0 !important;
  width: 16px !important;
  height: 16px !important;
  font-size: 16px !important;
  line-height: 16px !important;
}

.icon24,
.icon24 * {
  margin: 0 !important;
  padding: 0 !important;
  width: 24px !important;
  height: 24px !important;
  font-size: 24px !important;
  line-height: 24px !important;
}

/* material-table className must be on the parent of the MaterialTableComponent */
/* because the HtmlTable is wrapped in a Div by material */
/* and that Div has "overflow-x" set as hidden which is need as auto */
/* to slide horizontally the content in small screens */
.material-table {
  margin-top: 8px;
}

.material-table table {
  table-layout: auto !important;
}

.material-table>div>div:nth-child(2) {
  overflow-x: auto !important;
}

.material-table td {
  white-space: normal !important;
}

.padding-top-64 {
  padding-top: 64px !important;
}

.padding-top-96 {
  padding-top: 96px !important;
}

.collection-controls {
  text-align: right;
}

.collection-info {
  text-align: left;
  padding: 0 16px;
  min-width: 130px;
  height: 36px;
  line-height: 36px;
}

.inactive {
  opacity: 0.5 !important;
}

.input-1,
.input-1 input {
  height: 40px !important;
  line-height: 40px !important;
}

.input-1 input {
  background-color: #fff !important;
  border: 1px solid #dedede !important;
  border-radius: 3px !important;
  padding-left: 10px !important;
}

.input-1 hr {
  display: none !important;
}

.item {
  background-color: #f7f7f7 !important;
  border: 0.2px solid #dedede !important;
  border-radius: 3px !important;
  margin: 10px !important;
}

.outline {
  outline: 1px solid !important;
}

.outlines>* {
  outline: 1px solid !important;
}

.width-auto {
  width: auto !important;
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}

.animated-background {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #ffffff;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background-size: 800px 104px;
  height: 96px;
  position: relative;
}

@media only screen and (max-width: 320px) {}

@media only screen and (max-width: 375px) {}

@media only screen and (max-width: 768px) {
  .row-card .contents {
    margin: 16px 0 24px;
  }

  .row-card .details span {
    width: 30% !important;
  }

  .filter-control-search-input {
    width: calc(100% - 4px) !important;
  }
}

@media only screen and (max-width: 960px) {
  .match-left-side {
    width: 100%;
    padding: 8px 24px 16px;
  }

  .match-right-side {
    width: 100%;
  }
}

/** HACKS */
.card-picker-date-tf>div:before {
  border-bottom: none !important;
}

.card-picker-date-tf input {
  max-width: 188px;
}

/*
  .MuiInput-underline-19:after {
    border-bottom: 0px solid var(--purple)!important;
  }
  */
.stage-col-include-reason,
.stage-col-include-reason * {
  font-size: 13px !important;
  max-width: 160px;
}

iframe.covered {
  border: none;
  width: 100%;
  height: 100%;
}

/* FROM HERE NEW  */

.filter-skip {
  color: lightgray !important;
  border: 1px solid lightgray !important;
  background-color: white !important;
  height: 30px !important;
  line-height: 30px !important;
  margin-right: 5px !important;
  min-width: 50px !important;
}

.filter-right-heading {
  color: white !important;
  /* border: 1px solid lightgray !important; */
  background-color: #3661b6 !important;
  height: 30px !important;
  line-height: 30px !important;
  margin-right: 5px !important;
  min-width: 50px !important;
}

/* NEW BUTTONS */

.buttons-default {
  border-radius: 20px !important;
  margin-right: 5px !important;
  color: gray !important;
  font-size: 10px !important;
  height: 30px !important;
  line-height: 30px !important;
  margin-bottom: 5px !important;
}

.buttons-simple {
  border: 1px solid gray !important;
}

.buttons-valued {
  border: 1px solid green !important;
}

.buttons-not-valued {
  border: 1px solid red !important;
}

.candidate-match-toolbar {
  /* background-color: white; */
  font-size: 12px;
  border-bottom: 1px solid lightgray;
  /* border-right: 1px solid lightgray; */
  /* color: gray; */
}

.candidate-match-toolbar .toolbar-left span,
.toolbar-right span {
  margin-right: 10px;
}

.toolbar-button {
  /* padding: 10px 0px; */
  text-align: center;
  /* box-shadow: rgb(204, 204, 204) 5px 1px 10px 0px; */
  /* margin: 12px; */
}

.toolbar-left {
  padding: 19px 0px 19px 48px;
  border-right: 1px solid lightgray;
}

.toolbar-right {
  padding: 19px 0px 19px 48px;
}

.heading-right {
  font-size: 12px;
  padding: 19px 48px;
}

.heading-skills {
  background-color: white;
  border-bottom: 1px solid lightgray;
}

.heading-center {
  text-align: center;
  padding: 6px;
  font-weight: 500;
}

.heading-center i {
  position: relative;
  top: 5px;
}

.heading-center span {
  margin-right: 20px;
  font-size: 14px;
}

.heading-left {
  padding: 13px 50px 9px 50px;
  float: right;
  font-size: 12px;
}

.must-have-text {
  color: green;
  text-decoration: underline;
}

.subm-write {
  padding: 20px;
  font-size: 15px;
}

.match-new {
  color: #454545;
}

.match-new a {
  text-decoration: none;
  cursor: pointer;
}

.match-new.candidate-name {
  margin-bottom: 7px;
  font-size: 16px;
}

.match-new.candidate-attributes p {
  margin: 3px 5px 3px 0px;
  font-size: 14px;
}

.match-new.candidate-attributes p:nth-last-child(1) {
  margin: 3px 5px 16px 0px;
}

.match-new.candidate-attributes p:first-child span {
  font-size: 12px;
  margin: 5px 5px 5px 0px;
}

.match-new.candidate-name span {
  font-size: 10px;
}

.match-new.recruiter-name {
  font-size: 14px !important;
}

.match-new.section-card {
  /* padding: 16px 10px; */
  padding: 0px 0px;
  box-shadow: 0px 0px 10px #888888;
}

.match-new.match-new.job-list-card-header {
  margin: 0px;
  font-size: 14px;
  font-weight: bold;
  padding: 3px 10px 3px 10px;
}

.match-new.match-new.candidate-list-card-header {
  margin: 0px;
  font-size: 14px;
  padding: 3px 10px 3px 10px;
}

.match-new.job-list-card-content {
  padding: 1px 10px;
  color: #454545 !important;
  padding-bottom: 10px !important;
}

.match-new.card-content {
  padding: 1px 10px;
  box-shadow: 0px 0px 10px #888888;
}

.match-new.card-content p {
  margin: 8px 0px 4px 0px;
  font-size: 14px;
}

.match-new.section-header {
  font-size: 16px;
  margin: 1px 1px;
  text-align: center;
}

.match-new.heading-center {
  font-size: 16px;
  margin: 1px 1px;
  padding: 6px 0px 0px 0px;
}

.match-new.heading-center span {
  font-size: 14px;
}

p.job-list-tabs {
  font-size: 10px;
  display: flex;
  justify-content: space-between;
  margin-right: 15px;
}

p.job-list-tabs a {
  cursor: pointer;
  color: #454545 !important;
  text-decoration-color: #454545 !important;
  border-bottom: 3px solid #fff;
}

p.job-list-tabs a:hover {
  border-bottom: 3px solid #665ff5;
  cursor: pointer;
}

p.job-list-tabs a strong.job-list-tab-active {
  border-bottom: 3px solid #665ff5;
}

.list-card-section.disable {
  display: none;
}

.list-card-section.disable+div>.MuiPaper-root-138 {
  background-color: #fff !important;
}

.list-card-section.disable+div div strong {
  display: none;
}

.match-new .subm-write {
  padding: 0px;
  font-size: 14px;
}

.match-new.span-date {
  font-size: 10px;
  margin: 0px 1px;
}

.match-new.section-card hr {
  margin: 1px 0px;
}

.match-new.job-attributes {
  /* font-weight: bold; */
  font-size: 16px;
  margin: 16px 0px 7px 0px;
}

.match-new.job-title {
  font-size: 14px;
  margin: 5px 5px 5px 0px;
}

.match-new .rich-text-box .public-DraftEditor-content {
  font-size: 14px;
}

.match-new div.text-edit {
  font-size: 14px !important;
}

.job-card {
  color: #454545;
}

.job-card-title {
  margin: 0px;
  font-size: 14px;
  font-weight: bold;
}

.match-new.job-card-text {
  font-size: 13px !important;
  color: #454545 !important;
  line-height: 110% !important;
}

.match-new.job-list-card-content p {
  margin-top: 0px;
  margin-bottom: 3px;
}

.MuiCardContent-root-181 {
  padding-bottom: 1px !important;
}

.match-new.drawer div:nth-child(1) {
  width: 75%;
}

.match-new.drawer-text {
  font-size: 13px;
  margin: 5px 3px 5px 3px;
  padding: 0px 3px;
}

/* job requirement */
.main-req {
  padding: 20px;
}

.save-job {
  text-align: center;
}

.save-job .filter-cancel {
  color: #536dfe !important;
  border: 1px solid #536dfe !important;
}

.save-job .filter-save {
  color: white !important;
  background-color: #536dfe !important;
  border: 1px solid #536dfe !important;
}

.save-job .ml10 {
  margin-left: 10px !important;
}

.save-job .uppercase {
  text-transform: uppercase;
}

.job-font-16 {
  font-size: 16px;
}

.job-font-14 {
  font-size: 14px;
}

.job-font-12 {
  font-size: 12px;
}

.job-font-10 {
  font-size: 10px;
}

.skills-add {
  border: 2px solid #536dfe;
  border-radius: 10px;
  padding: 0px 4px;
  color: #536dfe;
  font-size: 18px;
  font-weight: 400;
  cursor: pointer;
}

.must-have-req {
  margin-bottom: 10px;
}

.outer-grid {
  font-size: 14px;
  color: lightslategrey;
}

.x-icon {
  text-align: center;
}

.pt-10 {
  padding-top: 10px;
}

.skills-remove {
  border: 1px solid lightslategray;
  border-radius: 10px;
  padding: 2px 5px;
  font-size: 10px;
  cursor: pointer;
}

.main-skills {
  border: 1px solid lightslategray;
  border-radius: 10px;
  padding: 2px 5px;
  font-size: 14px;
  cursor: pointer;
}

.outer-options {
  border: 1px solid #536dfe;
  border-radius: 4px;
  padding: 2px 5px;
  font-size: 14px;
  margin-left: 5px;
  cursor: pointer;
}

.inner-1 {
  border: 1px solid #536dfe;
  border-radius: 10px;
  font-size: 10px;
  padding: 0px 3px;
  color: #536dfe;
}

.inner-2 {
  margin-left: 4px;
  font-size: 10px;
  color: #536dfe;
}

.switch-skills {
  cursor: pointer;
  text-decoration: underline;
  font-size: 10px;
}

.job-skills-popup {
  padding: 20px;
  box-shadow: 1px 0px 5px 2px #ccc;
  margin-bottom: 30px;
}

.job-tab.active-tab {
  color: #536dfe;
}

.job-tab {
  font-size: 12px;
  color: lightslategray;
  text-decoration: underline;
  margin-bottom: 10px !important;
  cursor: pointer;
}

.job-specify {
  font-size: 14px;
  margin-bottom: 10px !important;
}

.mr-10 {
  margin-right: 10px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.filter-popup-button {
  color: #00ced1 !important;
}

.ml10 {
  margin-left: 10px !important;
}

.job-custom-label {
  font-size: 14px;
  margin-top: 16px !important;
  color: lightslategray;
}

.text-right {
  text-align: right;
}

.search-icon {
  max-width: 25px !important;
  margin-top: 16px !important;
}

.req-heading {
  color: green !important;
}

.skills-heading {
  font-weight: 500 !important;
  font-size: 18px !important;
}

.skills-sub-heading {
  color: lightslategray !important;
  margin-left: 5px !important;
}

.slim-chip {
  height: auto !important;
  padding: 0px 0px !important;
  color: #454545 !important;
  margin: 1px 3px 4px 0px;
  max-width: 98%;
}

.slim-chip span {
  padding-left: 3px;
  padding-right: 3px;
}

.slim-chip svg {
  padding-left: 5px;
  padding-right: 0px;
}

.star-menu-item {
  display: flex !important;
  align-items: center;
}

.chip-button {
  border: 1px solid var(--cyan) !important;
  border-radius: 2px !important;
  color: #ffffff !important;
}

.display-flex {
  display: flex !important;
}

.material-v4-form-control * {
  color: rgba(0, 0, 0, 0.54) !important;
  font-size: 0.8rem !important;
}

.App {
  max-width: 100vw;
  overflow: hidden;
}

.Home {
  background-color: #f7f7f7;
}

.Home * {
  font-family: 'Roboto', sans-serif;
}

.Home-Drawer-List {
  text-align: center;
}

.Home-middle {
  min-height: 100vh;
  text-align: right;
  position: relative;
  padding-top: 64px;
}

.Home-middle-list-left {
  display: inline-block;
  vertical-align: top;
  width: 100px;
  text-align: center;
  position: fixed;
  top: 64px;
  left: 0;
}

.Home-middle-list-left * {
  text-align: center;
  font-size: 11px;
}

.Home-middle-list-right {
  vertical-align: top;
  padding: 0;
}

.Home-middle .Home-middle-list-right .Home-Routes .snack-bar * {
  text-align: center !important;
}

.Home-middle-list-right * {
  text-align: left;
}

.Home-AppBar {
  background-color: white;
  text-align: left;
  position: fixed !important;
  overflow: hidden;
}

.Home-AppBar * {
  color: #212121;
}

.Home-AppBar path {
  fill: #212121;
}

.Home-AppBar .tabs {
  display: inline-block;
  width: calc(100vw - 600px);
  margin: -6px 240px 0 0;
  text-align: left;
  height: 60px;
}

.Home-AppBar .tabs .center {
  display: table;
  width: auto;
  margin: auto;
}

.Home-AppBar .tabs .navlink {
  display: inline-block;
  margin: 0 32px;
}

.Home-AppBar .tabs .navlink i {
  margin-top: 7px;
}

.Home-AppBar .tabs .navlink span {
  line-height: 28px;
}

.Home-AppBar .actions {
  display: inline-block;
  width: 150px;
}

@media only screen and (max-width: 768px) {
  .Home-middle-list-left {
    display: none;
  }
}

.navlink {
  text-decoration: none;
}

a.navlink.active>div>span>div>div {
  color: #536dfe !important;
}

img.avatar {
  border-radius: 50%;
  height: 56px;
  width: 56px;
}

.navlink {
  cursor: pointer !important;
}

.navlink span {
  font-size: 12px;
  line-height: 3em;
  padding: 0 16px;
}

/*
.navlink.active {
  border-bottom: 5px solid #536dfe;
}
*/

.normal-weight {
  font-weight: 500;
}

.no-wrap,
.no-wraps * {
  white-space: nowrap;
}

.wrap {
  white-space: break-spaces !important;
}

.white {
  color: white;
}

.align-left {
  text-align: left !important;
}

.align-right {
  text-align: right !important;
}

.center,
.center * {
  text-align: center !important;
}

.centered-wrapper {
  width: 80% !important;
  margin: auto !important;
}

.centered-content {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.centered-content>* {
  width: auto;
  height: auto;
  margin: auto;
}

.chips .chip,
.Tags .chip {
  display: inline-flex !important;
}

.chips .chip path,
.Tags .chip path {
  fill: #ffffff !important;
}

.not-found {
  padding: 16px;
  /*
    max-width: 856px;
  */
  margin: auto;
  color: gray;
  font-size: 14px;
}

.package-version {
  display: block;
  position: absolute !important;
  top: 0;
  right: 0;
  width: auto;
  height: 11px;
  color: white;
  z-index: 9999;
  line-height: 11px;
  font-size: 11px;
  font-weight: normal;
  margin: 4px 20px;
  cursor: pointer;
}

.debug {
  display: block;
  position: fixed !important;
  bottom: 0;
  left: 0;
  width: 100%;
  max-height: 50vh;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  z-index: 9999;
  line-height: 14px;
  font-size: 12px;
  font-weight: normal;
  padding: 4px;
  margin: 0;
  overflow: auto;
  cursor: pointer;
}

.debug:empty {
  padding: 0;
}

.padding-16 {
  padding: 16px !important;
}

.subheader {
  color: rgba(0, 0, 0, 0.74);
  font-weight: bold;
}

.row {
  justify-content: space-between;
  display: flex;
}

.cols-1>* {
  display: block !important;
  width: 100%;
}

.cols-2 {
  display: flex !important;
  flex-wrap: nowrap;
}

.cols-2>* {
  width: 50%;
}

.cols-3 {
  display: flex !important;
  flex-wrap: nowrap;
}

.cols-3>* {
  width: 33.33%;
}

.cols-4 {
  display: flex !important;
  flex-wrap: nowrap;
}

.cols-4>* {
  width: 25%;
}

.cols-5 {
  display: flex !important;
  flex-wrap: nowrap;
}

.cols-5>* {
  width: 20%;
}

.cols-6 {
  display: flex !important;
  flex-wrap: nowrap;
}

.cols-6>* {
  width: 16.66%;
}

.cols-7 {
  display: flex !important;
  flex-wrap: nowrap;
}

.cols-7>* {
  width: 14.28%;
}

.cols-8 {
  display: flex !important;
  flex-wrap: nowrap;
}

.cols-8>* {
  width: 12.5%;
}

@media (max-width: 375px) {

  .cols-2.flexible>*,
  .cols-3.flexible>*,
  .cols-4.flexible>*,
  .cols-5.flexible>*,
  .cols-6.flexible>*,
  .cols-7.flexible>*,
  .cols-8.flexible>* {
    width: 100%;
  }
}

@media (min-width: 375px) and (max-width: 768px) {

  .cols-2.flexible>*,
  .cols-3.flexible>*,
  .cols-4.flexible>* {
    width: 100%;
  }

  .cols-5.flexible,
  .cols-6.flexible,
  .cols-7.flexible,
  .cols-8.flexible {
    flex-wrap: unset;
  }

  .cols-5.flexible>*,
  .cols-6.flexible>*,
  .cols-7.flexible>*,
  .cols-8.flexible>* {
    min-width: 320px;
    width: 50%;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {

  .cols-2.flexible,
  .cols-3.flexible,
  .cols-4.flexible,
  .cols-5.flexible,
  .cols-6.flexible,
  .cols-7.flexible,
  .cols-8.flexible {
    flex-wrap: unset;
  }

  .cols-2.flexible>*,
  .cols-3.flexible>*,
  .cols-4.flexible>* {
    min-width: 320px;
    width: 50%;
  }

  .cols-5.flexible>*,
  .cols-6.flexible>*,
  .cols-7.flexible>*,
  .cols-8.flexible>* {
    min-width: 320px;
    width: 25%;
  }
}

.w2 {
  width: 2vw !important;
}

.w5 {
  width: 5vw !important;
}

.w10 {
  width: 10vw !important;
}

.w20 {
  width: 20vw !important;
}

.w25 {
  width: 25vw !important;
}

.w30 {
  width: 30vw !important;
}

.w33 {
  width: 33.33vw !important;
}

.w40 {
  width: 40vw !important;
}

.w50 {
  width: 50vw !important;
}

.w60 {
  width: 60vw !important;
}

.w66 {
  width: 66.66vw !important;
}

.w70 {
  width: 70vw !important;
}

.w75 {
  width: 75vw !important;
}

.w80 {
  width: 80vw !important;
}

.w90 {
  width: 90vw !important;
}

.w100 {
  width: 100vw !important;
}

.w5p {
  width: 5% !important;
}

.w10p {
  width: 10% !important;
}

.w20p {
  width: 20% !important;
}

.w25p {
  width: 20% !important;
}

.w30p {
  width: 30% !important;
}

.w40p {
  width: 40% !important;
}

.w50p {
  width: 50% !important;
}

.w60p {
  width: 60% !important;
}

.w70p {
  width: 70% !important;
}

.w75p {
  width: 75% !important;
}

.w80p {
  width: 80% !important;
}

.w90p {
  width: 90% !important;
}

.w100p {
  width: 100% !important;
}

.col1 {
  width: 8.33% !important;
}

.col2 {
  width: 16.66% !important;
}

.col3 {
  width: 25% !important;
}

.col4 {
  width: 33.33% !important;
}

.col5 {
  width: 41.66% !important;
}

.col6 {
  width: 50% !important;
}

.col7 {
  width: 58.33% !important;
}

.col8 {
  width: 66.66% !important;
}

.col9 {
  width: 75% !important;
}

.col10 {
  width: 83.33% !important;
}

.col12 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.w240 {
  width: 240px !important;
}

.w320 {
  width: 320px !important;
}

.w400 {
  width: 400px !important;
}

.maxW80p {
  max-width: 80% !important;
}

.ui-g {
  padding: 16px 0;
}

.ui-g-2 {
  display: inline-block;
  width: calc(16.66% - 32px);
  padding: 0 16px;
}

.ui-g-4 {
  display: inline-block;
  width: calc(33.33% - 32px);
  padding: 0 16px;
}

.ui-g-6 {
  display: inline-block;
  width: calc(50% - 32px);
  padding: 0 16px;
}

.ui-g-8 {
  display: inline-block;
  width: calc(66.66% - 32px);
  padding: 0 16px;
}

.ui-g-10 {
  display: inline-block;
  width: calc(83.33% - 32px);
  padding: 0 16px;
}

.ui-g-12 {
  display: block;
  width: 100%;
  padding: 0 16px;
}

/** MINIMUM DISPLAY */
@media only screen and (max-width: 320px) {
  .ui-xs-min {
    display: none !important;
  }
}

@media only screen and (max-width: 375px) {
  .ui-s-min {
    display: none !important;
  }
}

@media only screen and (max-width: 768px) {
  .ui-m-min {
    display: none !important;
  }
}

@media only screen and (max-width: 1024px) {
  .ui-l-min {
    display: none !important;
  }
}

@media only screen and (max-width: 1200px) {
  .ui-xl-min {
    display: none !important;
  }
}

/** MAXIMUM DISPLAY */
@media only screen and (min-width: 320px) {
  .ui-xs-max {
    display: none !important;
  }
}

@media only screen and (min-width: 375px) {
  .ui-s-max {
    display: none !important;
  }
}

@media only screen and (min-width: 768px) {
  .ui-m-max {
    display: none !important;
  }
}

@media only screen and (min-width: 1024px) {
  .ui-l-max {
    display: none !important;
  }
}

@media only screen and (min-width: 1200px) {
  .ui-xl-max {
    display: none !important;
  }
}

.MuiInput-underline-54:before {
  left: 0;
  right: 0;
  bottom: 0;
  content: '\00a0';
  position: absolute;
  transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-bottom: 1px solid rgb(224, 224, 224) !important;
  pointer-events: none;
}

.MuiInput-underline-54:after {
  left: 0;
  right: 0;
  bottom: 0;
  content: '\00a0';
  position: absolute;
  transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-bottom: 2px solid rgb(89, 115, 111) !important;
  pointer-events: none;
}

.card-picker-date-tf .MuiInputBase-root-48 {
  width: 100%;
}

.expanded-view table td {
  padding-left: 8px !important;
  padding-right: 8px !important;
}

.µ-fieldset-header,
.µ-public-job-desc {
  display: flex;
  place-content: flex-start center;
  margin-top: 0.5rem;
}

.µ-fieldset-header {
  place-content: flex-start;
}

.µ-fieldset-header>* {
  align-self: center;
}

label.legend,
.col>div>div>.legend,
.µ-fieldset-header label,
.µ-public-job-desc label {
  display: inline-block !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  color: #4f8078 !important;
  margin-right: 2rem;
  width: 256px;
}

.µ-fieldset-header label,
.µ-public-job-desc label {
  align-self: center;
}

.µ-public-job-desc input {
  display: inline-block;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  padding: 0.4rem 1rem;
  outline: 0 !important;
}

.µ-public-job-desc input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--placeholder);
}

.µ-snack-bar {
  background-color: rgba(200, 166, 0, 0.9);
  top: 4.5rem;
  bottom: auto !important;
  border-radius: 4px;
  overflow: auto;
}

.µ-snack-bar>* {
  background-color: transparent !important;
  height: auto !important;
  width: auto !important;
  min-width: 25vw !important;
  max-width: 100vw !important;
  max-height: calc(100vh - 5rem) !important;
  text-align: center !important;
}

.µ-snack-bar div {
  line-height: 2.5rem !important;
}

.µ-snack-bar span {
  font-size: 1.5rem !important;
  font-weight: bold !important;
  color: rgba(255, 255, 255, 0.9) !important;
}

.µ-textfield {
  background-color: transparent !important;
  border: 0;
  border-bottom: 1px solid gray !important;
  outline: 0 !important;
  padding: 0.5rem 0;
}

.µ-textfield:hover {
  border-bottom: 1px solid black !important;
}

.µ-textfield:focus {
  border-bottom: 1px solid var(--purple) !important;
}

.bg-0 {
  background-color: transparent !important;
}

.bg-red {
  background-color: red !important;
}

.bg-a-02 {
  background-color: rgba(0, 0, 0, 0.02) !important;
}

.bg-a-05 {
  background-color: rgba(0, 0, 0, 0.05) !important;
}

.border-red {
  border: 1px solid red !important;
}

.border-green {
  border: 1px solid green !important;
}

.border-gray {
  border: 1px solid gray !important;
}

/** @todo review impact of set !important on current code 2021-09-22 Wed µ */
.d-flex {
  display: flex !important;
}

.d-none {
  display: none !important;
}

.d-block {
  display: block;
}

.d-inline-block {
  display: inline-block;
}

.flex-column {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-align-left-center {
  text-align: left !important;
  justify-items: left;
  justify-content: left !important;
  align-items: center !important;
}

.flex-align-right-center {
  text-align: right !important;
  justify-items: right !important;
  justify-content: flex-end !important;
  align-items: center !important;
}

.flex-align-right-top {
  text-align: right !important;
  justify-items: right !important;
  justify-content: flex-end !important;
  align-items: top !important;
}

.flex-align-center {
  text-align: center !important;
  justify-items: center !important;
  justify-content: center !important;
  align-items: center !important;
}

.align-center {
  align-items: center;
}

.t-align-right {
  text-align: right !important;
}

.justify-start {
  justify-items: flex-start;
}

.c-light {
  color: #aaa;
}

.c-black {
  color: var(--commonBlack) !important;
}

.c-gray {
  color: gray !important;
}

.c-red {
  color: red !important;
}

.c-yellow {
  color: yellow !important;
}

.c-green {
  color: green !important;
}

.c-white {
  color: white !important;
}

.c-failure {
  color: crimson !important;
}

.c-orange {
  color: orange !important;
}

.c-warning {
  color: gold !important;
}

.c-success {
  color: limegreen !important;
}

.c-white,
.c-white span {
  color: white !important;
}

.f-12 {
  font-size: 12px !important;
}

.w-auto {
  width: auto !important;
}

.last-b-0>tbody>tr:last-child>td {
  border-bottom: 0 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.5rem !important;
}

.m-2 {
  margin: 1rem !important;
}

.ml-1 {
  margin-left: 0.5rem !important;
}

.ml-2 {
  margin-left: 1rem !important;
}

.ml-auto {
  margin-left: auto !important;
}

.mr-1 {
  margin-right: 0.5rem !important;
}

.mr-2 {
  margin-right: 1rem !important;
}

.mr-3 {
  margin-right: 1.5rem !important;
}

.mr-4 {
  margin-right: 2rem !important;
}

.mr-6 {
  margin-right: 3rem !important;
}

.mr-8 {
  margin-right: 4rem !important;
}

.mr-auto {
  margin-right: auto !important;
}

.mb-1 {
  margin-bottom: 0.5rem !important;
}

.mb-2 {
  margin-bottom: 1rem !important;
}

.mt-0 {
  margin-top: 0rem !important;
}

.mt-1 {
  margin-top: 0.5rem !important;
}

.mt-2 {
  margin-top: 1rem !important;
}

.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.mx-1 {
  margin-left: 0.5rem !important;
  margin-right: 0.5rem !important;
}

.my-1 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-2 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.overflow-unset {
  overflow: unset !important;
}

.overflow-auto {
  overflow: auto !important;
}

.p-0 {
  padding: 0rem !important;
}

.p-1 {
  padding: 0.5rem !important;
}

.p-2 {
  padding: 1rem !important;
}

.pb-1 {
  padding-bottom: 0.5rem !important;
}

.pb-2 {
  padding-bottom: 1rem !important;
}

.pt-1 {
  padding-top: 0.5rem !important;
}

.pt-2 {
  padding-top: 1rem !important;
}

.pt-3 {
  padding-top: 1.5rem !important;
}

.pt-4 {
  padding-top: 2rem !important;
}

.px-0 {
  padding-left: 0rem !important;
  padding-right: 0rem !important;
}

.py-0 {
  padding-top: 0rem !important;
  padding-bottom: 0rem !important;
}

.py-1 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-2 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.px-1 {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}

.px-2 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.px-3 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.pr-0 {
  padding-right: 0rem !important;
}

.pl-1 {
  padding-left: 0.5rem !important;
}

.s-16 {
  width: 16px !important;
  height: 16px !important;
}

.rounded {
  border-radius: 0.5em !important;
}

.to-upper-case {
  text-transform: uppercase !important;
}

.w-col-1 {
  width: calc(20% - 0.5rem) !important;
  min-width: calc(160px - 0.5rem) !important;
}

.w-20 {
  width: 20% !important;
}

.w-22 {
  width: 22% !important;
}

.w-33 {
  width: 33% !important;
}

.w-40 {
  width: 40% !important;
}

.w-col-2 {
  width: calc(40% - 0.5rem) !important;
  min-width: calc(320px - 0.5rem) !important;
}

.w-44 {
  width: 44% !important;
}

.w-50 {
  width: 50% !important;
}

.w-80 {
  width: 80% !important;
}

.w-100 {
  width: 100% !important;
}

.h-100 {
  height: 100% !important;
}

.h-md {
  min-height: calc(320px - 1rem);
  height: 996px !important;
  max-height: calc(100vh - 2rem) !important;
}

.w-sm {
  min-width: calc(320px - 1rem);
  width: 768px !important;
  max-width: calc(100vw - 2rem) !important;
}

.w-md {
  min-width: calc(320px - 1rem);
  width: 996px !important;
  max-width: calc(100vw - 2rem) !important;
}

.max-w-md {
  max-width: 996px !important;
}

.min-w {
  min-width: calc(320px - 1rem);
}

.min-w-160 {
  min-width: 160px !important;
}

.min-w-240 {
  min-width: 240px !important;
}

.min-w-320 {
  min-width: 320px !important;
}

/* - antd select css overwrite - */

.ant-picker:hover,
.ant-picker:focus,
.ant-picker-focused,
.ant-input:hover,
.ant-input:focus,
.ant-select-selector:hover,
.ant-select-focused .ant-select-selector {
  border-color: var(--purple) !important;
}

.ant-select-disabled .ant-select-selector:hover,
.ant-select-disabled .ant-select-selector {
  border-color: rgba(0, 0, 0, 0.2) !important;
}

.ant-select-selection-placeholder {
  color: var(--placeholder) !important;
}

.ant-select-tree-list-holder {
  overflow-y: scroll !important;
}

/* Chrome, Firefox, Opera, Safari 10.1+ */
::placeholder {
  color: var(--placeholder) !important;
  font-weight: normal;
  opacity: 1;
  /* Firefox */
}

/* Internet Explorer 10-11 */
:-ms-input-placeholder {
  color: var(--placeholder) !important;
}

/* Microsoft Edge */
::-ms-input-placeholder {
  color: var(--placeholder) !important;
}

.custom-dialog-body {
  max-height: calc(100vh - 260px) !important;
}

.br-1 {
  border-right: 1px solid #ddd !important;
}

.bl-1 {
  border-left: 1px solid #ddd !important;
}

.sticky-top {
  position: sticky !important;
  top: 0 !important;
  z-index: 1;
}

.sticky-bottom {
  position: sticky !important;
  bottom: 0 !important;
  z-index: 1;
}

.sticky-start {
  position: sticky !important;
  left: 0 !important;
  z-index: 1;
}

.sticky-end {
  position: sticky !important;
  right: 0 !important;
  z-index: 1;
}

.bg-main {
  background-color: var(--mainBgColor) !important;
}

.bg-eee {
  background-color: #eee !important;
}

.bg-white {
  background-color: white !important;
}

.bg-a-yellow {
  background-color: rgba(255, 222, 0, 0.5) !important;
}

.tanstack-resize .th {
  position: sticky !important;
  overflow: hidden;
}

.tanstack-resize .th .resizer {
  display: inline-block;
  width: 0px;
  height: 100%;
  border-left: 2px solid #ccc !important;
  border-right: 2px solid #ddd !important;
  position: absolute;
  right: 2px;
  top: 0;
  transform: translateX(50%);
  z-index: 1;
  touch-action: none;
}

.tanstack-resize .th .resizer.isResizing {
  border-left: 4px solid blue !important;
  border-right: 0px solid blue !important;
  right: 4px;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-truncate {
  /** to works it is needed define the width in your element */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-mono {
  font-family: 'Courier New', Courier, monospace !important;
}

.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.line-clamp:hover {
  display: block;
  overflow: initial;
}

.overflow-hidden {
  overflow: hidden !important;
}