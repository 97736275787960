.Stepper {
  color: black;
  position: relative;
  width: auto;
  height: 72px;
  padding: 16px 0 0;
  text-align: left;
}
.Stepper .conector {
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.1);
  top: 33px;
  left: 48px;
}
.Stepper .items {
  position: relative;
  width: auto;
}
.Stepper .before,
.Stepper .after {
  cursor: pointer;
}
.Stepper .before,
.Stepper .after,
.Stepper .active {
  background: none;
  outline: none;
  display: inline-block;
  width: 96px;
  height: auto;
  text-align: center;
}
.Stepper .step:hover .icon {
  background-color: #536dfe;
  border: 2px solid #536dfe;
  color: white;
}
.Stepper .step:hover .title {
  color: #536dfe;
}
.Stepper .active * {
  color: #536dfe !important;
}
.Stepper .active .icon {
  border: 2px solid #536dfe;
  color: #536dfe;
}
.Stepper .before .icon {
  background-color: rgb(0, 191, 165);
  border: 2px solid rgb(0, 191, 165);
  color: white;
}
.Stepper .before .title {
  color: rgb(0, 191, 165);
}
.Stepper .icon {
  background: white;
  border: 1.2px solid gray;
  color: gray;
  border-radius: 24px;
  width: 34px;
  height: 34px;
  line-height: 34px;
  text-align: center;
  display: block;
  margin: auto;
  font-size: 14px !important;
  font-weight: 500;
  cursor: default;
}
.Stepper .title {
  font-size: 12px !important;
  font-weight: 400;
  line-height: 32px;
  color: gray;
}

@media only screen and (max-width: 768px) {
}
@media only screen and (max-width: 380px) {
}
